import React, { useEffect, Suspense, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "../components/common/Header";
import ProtectedRoute from "../components/util/ProtectedRoute";
import User from "../components/pages/User";
import Document from "../components/pages/Document";
import ReactGA from "react-ga";

const Login = lazy(() => import("../components/auth/Login"));
const ErrorPage = lazy(() => import("../components/error/404"));
const Dashboard = lazy(() => import("../components/dashboard/Dashboard"));
const ManageUsers = lazy(() => import("../components/pages/ManageUsers"));
const DocumentEdit = lazy(() => import("../components/pages/DocumentEdit"));
const HtmlEdit = lazy(() => import("../components/pages/HtmlEdit"));
const ChatPdf = lazy(() => import("../components/chatwith/ChatPdf"));
const ChatUrl = lazy(() => import("../components/chatwith/ChatUrl"));

const ManageDocuments = lazy(() =>
  import("../components/pages/ManageDocuments")
);

const hideCommon = ["login", "auth"];

const Router = () => {
  const { pathname, search } = useLocation();
  const filterPath =
    pathname.split("/")[0] === ""
      ? pathname.split("/")[1]
      : pathname.split("/")[0];
  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname, search]);

  return (
    <>
      <Suspense fallback={<div className="css-loader">Loading...</div>}>
        {hideCommon.indexOf(filterPath) === -1 && <Header />}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/:userId"
            element={
              <ProtectedRoute>
                <User />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pdf-assistant/:userId/:assistantId"
            element={
              <ProtectedRoute>
                <ChatPdf />
              </ProtectedRoute>
            }
          />
          <Route
            path="/url-assistant/:userId/:assistantId"
            element={
              <ProtectedRoute>
                <ChatUrl />
              </ProtectedRoute>
            }
          />
          <Route
            path="/document/:documentId"
            element={
              <ProtectedRoute>
                <Document />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/document/:documentId"
            element={
              <ProtectedRoute>
                <DocumentEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/html/:documentId"
            element={
              <ProtectedRoute>
                <HtmlEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-users/"
            element={
              <ProtectedRoute>
                <ManageUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-documents/"
            element={
              <ProtectedRoute>
                <ManageDocuments />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default React.memo(Router);
