import React, { useState, useEffect, useRef } from "react";
import { getItem, clear } from "local-data-storage";
import { Link, useLocation } from "react-router-dom";
import { cdnImage } from "../../constants/constants";
import { FiChevronDown } from "react-icons/fi";
import { IoMenu } from "react-icons/io5";

const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { pathname } = useLocation();
  const navRef = useRef();

  const { value: userToken } = getItem("user-token") || "";
  const { value: gravatar } = getItem("gravatar") || "";
  const { value: user } = getItem("user") || {};
  const signOut = () => {
    clear();
    window.location.replace(process.env.REACT_APP_DOMAIN);
  };

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const closeNav = () => {
    setIsNavExpanded(false);
  };

  const filterPath =
    pathname.split("/")[0] === ""
      ? pathname.split("/")[1]
      : pathname.split("/")[0];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeNav();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="lignt-nav bg-white">
      <div className="container-fluid">
        <div className="row custom-row">
          <nav
            className={`navbar navbar-expand-lg topnav-menu zindex-10`}
            ref={navRef}
          >
            <a className="a navbar-brand logo" href={userToken ? "/" : "/"}>
              <img
                src={cdnImage.logo}
                height="30"
                className="img align-top logo-dark"
                alt="goPDF.pro - logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
              aria-controls="topnav-menu-content"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleNav}
            >
              <span className={`span navbar-toggler-icon text-dark`}>
                <IoMenu className="fs-40" />
              </span>
            </button>

            <div
              id="topnav-menu-content"
              className={`collapse navbar-collapse ${
                isNavExpanded ? "show" : ""
              }`}
            >
              <ul className="ul navbar-nav align-items-lg-center d-flex me-auto"></ul>

              <ul className="ul navbar-nav align-items-lg-center">
                {userToken && (
                  <li className="nav-item menu-secondary dropdown">
                    <a
                      className="a nav-link dropdown-toggle active"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Navigate <FiChevronDown />
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDocs">
                      <ul className="ul nav">
                        <li className="nav-item">
                          <Link
                            to="/manage-users"
                            className="a fs-15 fw-bolder nav-link"
                          >
                            Manage Users
                          </Link>
                        </li>
                        <li className="nav-item mb-2">
                          <Link
                            to="/manage-documents"
                            className="a fs-15 fw-bolder nav-link"
                          >
                            Manage Documents
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}
                {!userToken ? (
                  <li className="li nav-item ms-2">
                    <Link
                      to="/login"
                      className="a btn btn-sm btn-cta btn-primary"
                    >
                      Sign in
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item menu-secondary dropdown">
                    <a
                      className="nav-link dropdown-toggle hide"
                      href="#"
                      id="user"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src={gravatar}
                            className="img avatar avatar-xs rounded-circle me-2"
                            alt={user?.name}
                          />
                        </div>
                        <div className="flex-grow-1 ms-1 lh-base">
                          <span className="span fw-semibold fs-15 d-block line-height-normal">
                            {user?.name}
                          </span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-menu p-2" aria-labelledby="user">
                      <Link to="/dashboard" className="a dropdown-item p-2">
                        Dashboard
                      </Link>

                      <div className="dropdown-divider"></div>
                      <a className="a dropdown-item p-2" onClick={signOut}>
                        Sign Out
                      </a>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};
export default Header;
