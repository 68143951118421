import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { getItem } from "local-data-storage";
import { patchBearerData } from "../../services/patchbearerdata";
import { getBearerData } from "../../services/getbearerdata";
import { endPoint, toastStyle, s3Storage } from "../../constants/constants";
import toast from "react-hot-toast";
import { deleteBearerData } from "../../services/deletebearerdata";

const gravatar = require("gravatar");

const Document = () => {
  const navigate = useNavigate();
  const { value: token = "" } = getItem("user-token") || {};
  const [singleUser, setSingleUser] = useState({});
  const [singleDocument, setSingleDocument] = useState({});
  const [userId, setUserId] = useState("");

  const { documentId } = useParams();

  const getDocument = useCallback(
    async (documentId) => {
      try {
        const response = await getBearerData({
          path: `${endPoint.getAIDocument}/${documentId}`,
          token: token,
        });

        const data = await response?.data;
        if (data?.length < 1 || data === undefined) {
          return navigate(`/manage-documents`);
        }
        setSingleDocument(data?.data);
        setUserId(data?.data?.user);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch templates.", toastStyle);
      }
    },
    [token]
  );

  const deleteDocument = useCallback(
    async (documentId) => {
      try {
        const response = await deleteBearerData(
          `${endPoint.getAIDocument}/${documentId}`,
          token
        );
        if (response?.status === 204) navigate(`/manage-documents`);
      } catch (error) {
        console.error(error);
        toast.error("Failed to delete document.", toastStyle);
      }
    },
    [token]
  );

  const getUser = useCallback(
    async (userId) => {
      try {
        const response = await getBearerData({
          path: `${endPoint.users}/cs/${userId}`,
          token: token,
        });

        const data = await response?.data;
        setSingleUser(data?.user);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch user.", toastStyle);
      }
    },
    [token]
  );

  useEffect(() => {
    if (userId) getUser(userId);
  }, [userId]);

  useEffect(() => {
    if (documentId) {
      getDocument(documentId);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [documentId, getDocument]);

  const handleSubmit = async (values) => {
    try {
      const response = await patchBearerData(
        `${endPoint.updateAIDocument}/${documentId}`,
        values,
        token
      );
      if (response?.status === 200) {
        toast.success("Document updated successfully!", {
          position: "bottom-center",
          className: "react-toast",
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update document.", toastStyle);
    }
  };

  return (
    <section className="section bg-gradient2 position-relative p-3 account min-vh-100">
      <div className="container-fluid">
        <div className="row custom-row mb-3">
          <div className="col-12 col-lg-5">
            <div className="row">
              <div className="col-lg-12">
                <p className="p fs-14 mb-1 mt-4">Generated by:</p>
                <div
                  className="d-flex align-items-center mb-3 cursor-pointer"
                  onClick={() => navigate(`/user/${singleDocument?.user}`)}
                >
                  <img
                    src={gravatar.url(singleUser?.email)}
                    alt="image"
                    className="img-fluid avatar-sm rounded-circle avatar-border"
                  />
                  <span className="span fs-18 fw-semibold d-inline mx-2">
                    {singleUser?.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="card bg-white rounded border">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-pane fade show active">
                      {singleDocument?.id && (
                        <Formik
                          enableReinitialize
                          initialValues={{
                            title: singleDocument?.title,
                            baseId: singleDocument?.baseId,
                            type: singleDocument?.type,
                            docType: singleDocument?.docType,
                            prompt: singleDocument?.prompt,
                            accepted: singleDocument?.accepted,
                            pin: singleDocument?.pin || false,
                          }}
                          validationSchema={Yup.object({
                            title: Yup.string().required("Title is required"),
                            prompt: Yup.string().required("Prompt is required"),
                          })}
                          onSubmit={handleSubmit}
                        >
                          {({ touched, errors }) => (
                            <Form>
                              <div className="row align-items-center">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="title"
                                      className="label form-label"
                                    >
                                      Title<small>*</small>
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        errors.title && touched.title
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="title"
                                      placeholder="Document Title"
                                      name="title"
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      htmlFor="baseId"
                                      className="label form-label"
                                    >
                                      baseId
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm  ${
                                        errors.baseId && touched.baseId
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="baseId"
                                      placeholder="baseId"
                                      name="baseId"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="type"
                                      className="label form-label"
                                    >
                                      Type<small>*</small>
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm  ${
                                        errors.type && touched.type
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="type"
                                      aria-describedby="display_name"
                                      placeholder="Type"
                                      name="type"
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label className="label form-label">
                                      Document Type
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        errors.docType && touched.docType
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="docType"
                                      name="docType"
                                      placeholder="doc or html"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="address"
                                      className="label form-label"
                                    >
                                      Prompt<small>*</small>
                                    </label>
                                    <Field
                                      as="textarea"
                                      rows="5"
                                      className={`form-control form-control-sm fs-13 ${
                                        errors.prompt && touched.prompt
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="prompt"
                                      aria-describedby="display_name"
                                      placeholder="prompt"
                                      name="prompt"
                                    />
                                  </div>
                                </div>
                                <div className="col-6 col-lg-6">
                                  <div className="mb-3">
                                    <label className="label form-label">
                                      Accepted
                                    </label>
                                    <div>
                                      <label className={`switch`}>
                                        <Field
                                          type="checkbox"
                                          name="accepted"
                                          className="switch-input"
                                          id="accepted"
                                        />
                                        <span className="slider"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-lg-6">
                                  <div className="mb-3 float-right">
                                    <label className="label form-label">
                                      Pin
                                    </label>
                                    <div>
                                      <label className={`switch`}>
                                        <Field
                                          type="checkbox"
                                          name="pin"
                                          id="pin"
                                          className="switch-input"
                                        />
                                        <span className="slider"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="hr my-4" />
                              <div className="row mt-2">
                                <div className="col-lg-12">
                                  <button
                                    type="submit"
                                    className="btn btn-sm btn-secondary"
                                  >
                                    Update
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger float-right"
                                    onClick={() =>
                                      deleteDocument(singleDocument?.id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7 bg-white border rounded">
            <iframe
              src={`${s3Storage}/cp/ai-document/${process.env.REACT_APP_STORAGE_ENV}/${singleDocument?.baseId}/preview.html`}
              style={{ border: "none", height: "100vh", width: "100%" }}
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Document;
