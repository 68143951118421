import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getItem } from "local-data-storage";
import { patchBearerData } from "../../services/patchbearerdata";
import { getBearerData } from "../../services/getbearerdata";
import { endPoint, toastStyle } from "../../constants/constants";
import toast from "react-hot-toast";
import {
  BsFileEarmarkTextFill,
  BsFillFileEarmarkCodeFill,
  BsFillFileEarmarkPdfFill,
} from "react-icons/bs";
import { TbWorldWww } from "react-icons/tb";
const gravatar = require("gravatar");

const User = () => {
  const navigate = useNavigate();
  const token = getItem("user-token")?.value || "";
  const [singleUser, setSingleUser] = useState({});
  const { userId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [allAssistant, setAllAssistant] = useState([]);

  const getUserDocuments = useCallback(
    async (userId) => {
      try {
        const response = await getBearerData({
          path: `${endPoint.getUserDocuments}${userId}`,
          token: token,
        });
        const data = response.data;
        setDocuments(data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch documents.", toastStyle);
      }
    },
    [token]
  );

  const getUser = useCallback(
    async (userId) => {
      try {
        const response = await getBearerData({
          path: `${endPoint.users}/cs/${userId}`,
          token: token,
        });
        const data = response.data;
        setSingleUser(data.user);
        getUserDocuments(userId);
        setAllAssistant(data?.user?.allAssistant);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch user.", toastStyle);
      }
    },
    [token, getUserDocuments]
  );

  const documentList = documents?.map((el, i) => (
    <div className="document-box" key={`doc_${el?._id}`}>
      <div className="row document-dashboard text-center">
        <div className="col-10 col-md-10">
          {el?.type === "html" ? (
            <BsFillFileEarmarkCodeFill
              className="document text-secondary"
              onClick={() => navigate(`/user/html/${el._id}`)}
            />
          ) : (
            <BsFileEarmarkTextFill
              className="document text-primary"
              onClick={() => navigate(`/user/document/${el._id}`)}
            />
          )}
          <p className="p fs-14 card-title mt-2 mb-0">
            {el?.name &&
              (el.name.length > 26 ? el.name.slice(0, 26) + ".." : el.name)}
          </p>
        </div>
      </div>
    </div>
  ));

  const assistantList = allAssistant?.map((el, i) => (
    <div className="document-box" key={`doc_${el?.assistantId}`}>
      <div className="row document-dashboard text-center">
        <div className="col-10 col-md-10">
          {el?.type === "pdf" ? (
            <BsFillFileEarmarkPdfFill
              className="document text-danger"
              onClick={() =>
                navigate(`/pdf-assistant/${userId}/${el?.assistantId}`)
              }
            />
          ) : (
            <TbWorldWww
              className="document text-primary"
              onClick={() =>
                navigate(`/url-assistant/${userId}/${el?.assistantId}`)
              }
            />
          )}
          <p className="p fs-14 card-title mt-2 mb-0">
            {el?.assistantId &&
              (el.assistantId.length > 26
                ? el.assistantId.slice(0, 26) + ".."
                : el.assistantId)}
          </p>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    if (userId) {
      getUser(userId);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [userId, getUser]);

  return (
    <section className="section bg-gradient2 position-relative p-3 account min-vh-100">
      <div className="container-fluid">
        <div className="row custom-row mb-3">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center mt-4 mb-3">
                  <img
                    src={gravatar.url(singleUser?.email)}
                    alt="image"
                    className="img-fluid avatar-sm rounded-circle avatar-border"
                  />
                  <span className="span fs-18 fw-semibold d-inline mx-2">
                    {singleUser?.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="card bg-white rounded border">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-pane fade show active">
                      {singleUser?.email && (
                        <Formik
                          initialValues={{
                            name: singleUser?.name,
                            phone: singleUser?.phone,
                            email: singleUser?.email,
                            country: singleUser?.country,
                            address: singleUser?.address,
                            credit: singleUser?.credit,
                            automationToken: singleUser?.automationToken,
                            plan: singleUser?.plan,
                            isEmailVerified: singleUser?.isEmailVerified,
                          }}
                          validationSchema={Yup.object({
                            name: Yup.string().required("Name is required"),
                            credit: Yup.number().required("Credit is required"),
                            automationToken: Yup.number().required(
                              "automationToken is required"
                            ),
                          })}
                          onSubmit={async (values) => {
                            const response = await patchBearerData(
                              endPoint.users + "/cs/" + userId,
                              values,
                              token
                            );
                            if (response?.status === 200) {
                              toast.success("Account updated successfully!", {
                                position: "bottom-center",
                                className: "react-toast",
                              });
                            }
                          }}
                        >
                          {({ touched, errors }) => (
                            <Form>
                              <div className="row align-items-center">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="name"
                                      className="label form-label"
                                    >
                                      Name<small>*</small>
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        errors.name && touched.name
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="name"
                                      placeholder="Your Name"
                                      name="name"
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      htmlFor="phone"
                                      className="label form-label"
                                    >
                                      Phone
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm  ${
                                        errors.phone && touched.phone
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="phone"
                                      placeholder="Phone number"
                                      name="phone"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="email"
                                      className="label form-label"
                                    >
                                      Email<small>*</small>
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm  ${
                                        errors.email && touched.email
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="email"
                                      aria-describedby="display_name"
                                      placeholder="Email"
                                      name="email"
                                      disabled
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label className="label form-label">
                                      Country
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        errors.country && touched.country
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="country"
                                      name="country"
                                      placeholder="Country"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="address"
                                      className="label form-label"
                                    >
                                      Address<small>*</small>
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        errors.address && touched.address
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="address"
                                      aria-describedby="display_name"
                                      placeholder="Address"
                                      name="address"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="label form-label">
                                      Token Credit
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        errors.credit && touched.credit
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="credit"
                                      name="credit"
                                      placeholder="Credit"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="label form-label">
                                      Automation Token
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control form-control-sm ${
                                        errors.automationToken &&
                                        touched.automationToken
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      id="automationToken"
                                      name="automationToken"
                                      placeholder="Automation Token"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="label form-label">
                                      Plan
                                    </label>
                                    <Field
                                      as="select"
                                      className={`form-control form-control-sm ${
                                        errors.plan && touched.plan
                                          ? "border-danger"
                                          : singleUser?.plan === "paid"
                                          ? "border-success"
                                          : "border-warning"
                                      }`}
                                      id="plan"
                                      name="plan"
                                    >
                                      <option value="free">Free</option>
                                      <option value="paid">Paid</option>
                                    </Field>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="label form-label">
                                      isEmailVerified
                                    </label>
                                    <div>
                                      <label className={`switch`}>
                                        <Field
                                          type="checkbox"
                                          name="isEmailVerified"
                                          className="switch-input"
                                          id="isEmailVerified"
                                        />
                                        <span className="slider"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="hr my-4" />
                              <div className="row mt-2">
                                <div className="col-lg-12">
                                  <button
                                    type="submit"
                                    className="btn btn-sm btn-secondary"
                                  >
                                    Update User
                                  </button>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div>
              <h4 className="h4 mt-5 mt-0 fs-16 text-dark">Documents</h4>

              <div className="bg-white mt-3 pb-2 rounded border pb-0">
                {!documents ? (
                  <div
                    className="spinner-border text-primary mt-4 mb-5 mx-4"
                    role="status"
                  >
                    <span className="span visually-hidden">Loading...</span>
                  </div>
                ) : documents?.length === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <p className="h5 text-dark p-4">No Document found</p>
                    </div>
                  </div>
                ) : (
                  <div className="row">{documentList}</div>
                )}
              </div>
            </div>

            <div className="col-12">
              <h4 className="h4 mt-4 mt-0 fs-16 text-dark">Chat Assistant</h4>

              <div className="bg-white mt-3 pb-2 rounded border pb-0">
                {!allAssistant ? (
                  <div
                    className="spinner-border text-primary mt-4 mb-5 mx-4"
                    role="status"
                  >
                    <span className="span visually-hidden">Loading...</span>
                  </div>
                ) : allAssistant?.length === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <p className="h5 text-dark p-4">
                        No Chat Assistant found
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="row">{assistantList}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default User;
