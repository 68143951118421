import { inititalState } from "../store";

const initReducer = (state = inititalState, action) => {
  switch (action.type) {
    case "UPDATE_INIT_DATA":
      return {
        ...state,
        initData: action.payload,
      };
    default:
      return state;
  }
};
export default initReducer;
