import userReducer from "./userReducer";
import initReducer from "./initReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  userData: userReducer,
  initData: initReducer,
});

export default rootReducer;
