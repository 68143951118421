import axios from "axios";
import toast from "react-hot-toast";
import { removeItem, getItem, setItem } from "local-data-storage";
import { endPoint, toastStyle } from "../constants/constants";

const { value: refreshToken } = getItem("refresh-token") || "";

export const deleteBearerData = async (apiPath, token) => {
  if (!token) return;

  try {
    toast.loading("Requesting, please wait...", toastStyle);

    const response = await axios.delete(
      process.env.REACT_APP_API_PATH + apiPath,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    toast.dismiss();
    return response;
  } catch (error) {
    toast.dismiss();

    if (error?.response?.status === 401 && refreshToken) {
      try {
        const {
          data: {
            access: { token: newToken },
            refresh: { token: newRefreshToken },
          },
        } = await axios.post(
          process.env.REACT_APP_API_PATH + endPoint.refreshToken,
          { refreshToken }
        );

        removeItem("user-token");
        removeItem("refresh-token");
        setItem("user-token", { value: newToken });
        setItem("refresh-token", { value: newRefreshToken });

        toast.loading("Requesting, please wait...", toastStyle);

        const retryResponse = await axios.delete(
          process.env.REACT_APP_API_PATH + apiPath,
          { headers: { Authorization: `Bearer ${newToken}` } }
        );

        toast.dismiss();
        return retryResponse;
      } catch (refreshError) {
        toast.error("Session expired, please login again", toastStyle);
        setTimeout(() => {
          window.location.href = "/login";
        }, 1200);
      }
    } else {
      const { message } = error?.response?.data;
      toast.error(message ? message : "Something went wrong!", toastStyle);
    }
  }
};
