import axios from "axios";
import toast from "react-hot-toast";
import { removeItem, getItem, setItem, clear } from "local-data-storage";
import { endPoint, toastStyle } from "../constants/constants";

const { value: refreshToken } = getItem("refresh-token") || "";

export const getBearerData = async (dataSource) => {
  const { path, token, message } = dataSource;
  if (!token) return;
  toast.dismiss();
  try {
    toast.loading(message?.loading ?? "Requesting, please wait...", toastStyle);
    const response = await axios.get(process.env.REACT_APP_API_PATH + path, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.dismiss();
    return response;
  } catch (error) {
    toast.dismiss();
    if (error?.response?.status === 401 && refreshToken) {
      try {
        const {
          data: {
            access: { token: newToken },
            refresh: { token: newRefreshToken },
          },
        } = await axios.post(
          process.env.REACT_APP_API_PATH + endPoint.refreshToken,
          { refreshToken }
        );

        removeItem("user-token");
        removeItem("refresh-token");
        setItem("user-token", { value: newToken });
        setItem("refresh-token", { value: newRefreshToken });

        toast.loading("Requesting, please wait...", toastStyle);

        const retryResponse = await axios.get(
          process.env.REACT_APP_API_PATH + path,
          { headers: { Authorization: `Bearer ${newToken}` } }
        );
        toast.dismiss();
        return retryResponse;
      } catch (refreshError) {
        toast.error("Session expired, please login again", toastStyle);
        setTimeout(() => {
          clear();
          window.location.href = "/login";
        }, 1200);
      }
    } else {
      const { message } = error?.response?.data;
      toast.error(message ? message : "Something went wrong!", toastStyle);
    }
  }
};
