import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getItem } from "local-data-storage";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = useCallback(() => {
    const userToken = getItem("user-token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      navigate("/login");
    } else {
      setIsLoggedIn(true);
    }
  }, [navigate]);

  useEffect(() => {
    checkUserToken();
  }, [checkUserToken]);

  return isLoggedIn ? <React.Fragment>{children}</React.Fragment> : null;
};

export default ProtectedRoute;
