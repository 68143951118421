export const cdnImageBase = "https://images.gopdf.pro";
export const endPoint = {
  login: "auth/cs/login",
  refreshToken: "/auth/refresh-tokens",
  userDocuements: "document/user/docuements",
  createDocument: "document/create",
  getDocument: "document/cs",
  deleteDocument: "document",
  pdfGenerate: "/pdf/request",
  documentPdf: "pdf/document/request",
  users: "users",
  generateAiDocument: "document/generate",
  getAIDocument: "document/ai/doc",
  updateAIDocument: "document/ai/doc",
  getAllAIdocuments: "document/ai/all",
  getUserDocuments: "document/user/cs/docuements/",
  getRecentAIDocument: "document/ai/recent",
  searchAIDocuments: "document/ai/search?q=",
  searchUsers: "users/search?q=",
  getAllUsers: "users/getUsers",
  getRecentUsers: "users/recentUsers",
  chatPdfDelete: "chatpdf/d",
};
export const userInfo = "https://ipinfo.io/json";
export const s3Storage = "https://s3.gopdf.pro";
export const userTimeZone = {
  default: {
    currency: "$",
    prices: [
      {
        price: "5",
      },
      {
        price: "15",
      },
      {
        price: "35",
      },
      {
        price: "99",
      },
    ],
  },
  AsiaKolkata: {
    currency: "₹",
    prices: [
      {
        price: "299",
      },
      {
        price: "999",
      },
      {
        price: "2250",
      },
      {
        price: "7999",
      },
    ],
  },
  AustraliaSydney: {
    currency: "A$",
    prices: [
      {
        price: "7.6",
      },
      {
        price: "22.8",
      },
      {
        price: "53.3",
      },
      {
        price: "150.8",
      },
    ],
  },
  AmericaToronto: {
    currency: "CA$",
    prices: [
      {
        price: "6.7",
      },
      {
        price: "20.3",
      },
      {
        price: "47.4",
      },
      {
        price: "134.2",
      },
    ],
  },
  EuropeZurich: {
    currency: "Fr",
    prices: [
      {
        price: "4.4",
      },
      {
        price: "13.2",
      },
      {
        price: "30.1",
      },
      {
        price: "87.5",
      },
    ],
  },
  AsiaShanghai: {
    currency: "¥",
    prices: [
      {
        price: "36.0",
      },
      {
        price: "108.0",
      },
      {
        price: "252.0",
      },
      {
        price: "712.6",
      },
    ],
  },
  EuropeParis: {
    currency: "Є",
    prices: [
      {
        price: "4.5",
      },
      {
        price: "13.7",
      },
      {
        price: "32.1",
      },
      {
        price: "91.9",
      },
    ],
  },
  EuropeLondon: {
    currency: "£",
    prices: [
      {
        price: "3.9",
      },
      {
        price: "11.7",
      },
      {
        price: "27.5",
      },
      {
        price: "77.5",
      },
    ],
  },
  AsiaHong_Kong: {
    currency: "HK$",
    prices: [
      {
        price: "39.0",
      },
      {
        price: "117.2",
      },
      {
        price: "273.6",
      },
      {
        price: "774.1",
      },
    ],
  },
  AsiaTokyo: {
    currency: "¥",
    prices: [
      {
        price: "746.0",
      },
      {
        price: "2,238.2",
      },
      {
        price: "5,222.6",
      },
      {
        price: "14,772.4",
      },
    ],
  },
  PacificAuckland: {
    currency: "NZ$",
    prices: [
      {
        price: "11.2",
      },
      {
        price: "32.2",
      },
      {
        price: "72.6",
      },
      {
        price: "208.1",
      },
    ],
  },
  AsiaKarachi: {
    currency: "PKR",
    prices: [
      {
        price: "999.0",
      },
      {
        price: "2,999.0",
      },
      {
        price: "7,449.0",
      },
      {
        price: "27,684.0",
      },
    ],
  },
};
export const contactApi = "https://utf.gopdf.pro/contact";
export const supportApi = "https://utf.gopdf.pro/support";
export const pdfOrigin = "https://utf.gopdf.pro/pdf";
export const urlOrigin = "https://utf.gopdf.pro/url";
export const demoPdfOrigin = "https://utf.gopdf.pro/";
export const kbApi = "https://kb-api.gopdf.pro/";
export const cdnImage = {
  logo: `${cdnImageBase}/web/logo.png`,
  logoWhite: `${cdnImageBase}/web/logo-white.png`,
};

export const canny = {
  companyID: "658a6a05235a4d9f6c9c770a",
  redirect: "https://gopdf.canny.io/feature-request",
};

export const stripePlans = {
  price_1OvhY5JnoaEtPcpjLIScc8w9: "Personal",
  price_1OvhdtJnoaEtPcpjxnncGCYi: "Standard",
  price_1Ovhj3JnoaEtPcpjWhdnxAu1: "Business",
  price_1OvikNJnoaEtPcpjrxofCtQy: "Enterprise",
};
export const stripePlansArray = [
  {
    planId: "price_1OvhY5JnoaEtPcpjLIScc8w9",
    planName: "Personal",
    description: [
      "500 Tokens",
      "AI Chat with PDF",
      "Hosted Documents",
      "Document Generator",
      "Email Documents",
      "15 Auto Blog",
      "50MB per PDF",
      "Unlimited PDFs",
      "Unlimited Storage",
      "Support via email",
      "API Access",
      "+ Full Access",
    ],
    price: "5",
  },
  {
    planId: "price_1OvhdtJnoaEtPcpjxnncGCYi",
    planName: "Standard",
    description: [
      "2,500 Tokens",
      "AI Chat with PDF",
      "Hosted Documents",
      "Document Generator",
      "Email Documents",
      "35 Auto Blog",
      "75MB per PDF",
      "Unlimited PDFs",
      "Unlimited Storage",
      "API Access",
      "Support via email",
      "+ Full Access",
    ],
    price: "15",
    recommended: true,
  },
  {
    planId: "price_1Ovhj3JnoaEtPcpjWhdnxAu1",
    planName: "Business",
    description: [
      "6,000 Tokens",
      "AI Chat with PDF",
      "Hosted Documents",
      "Document Generator",
      "Email Documents",
      "75 Auto Blog",
      "75MB per PDF",
      "Unlimited PDFs",
      "Unlimited Storage",
      "API Access",
      "Support via call",
      "Commercial Use",
      "+ Full Access",
    ],
    price: "35",
  },
  {
    planId: "price_1OvikNJnoaEtPcpjrxofCtQy",
    planName: "Enterprise",
    description: [
      "22,000 Tokens",
      "AI Chat with PDF",
      "Hosted Documents",
      "Document Generator",
      "Email Documents",
      "200 Auto Blog",
      "75MB per PDF",
      "Unlimited PDFs",
      "Unlimited Storage",
      "API Access",
      "Priority Support",
      "Commercial Use",
      "Custom Integration",
      "+ Full Access",
    ],
    price: "99",
  },
];

export const unescapeHTML = function (html) {
  if (!html) return;
  var ret = html.replace(/&gt;/g, ">");
  ret = ret.replace(/&lt;/g, "<");
  ret = ret.replace(/&quot;/g, '"');
  ret = ret.replace(/&apos;/g, "'");
  ret = ret.replace(/&amp;/g, "&");
  return ret;
};

export const samplePdfs = {
  storyBook: "story-book",
  humanResource: "human-resource",
  legalTheory: "legal-theory",
  scienceLecture: "science-lecture",
  financialAnalysis: "financial-analysis",
  userManual: "user-manual",
};

export const toastStyle = {
  position: "bottom-center",
  className: "react-toast",
};
export const toastStyleLarge = {
  position: "bottom-center",
  className: "react-toast",
  width: "550px",
};
export const toastStyleMedium = {
  position: "bottom-center",
  className: "react-toast",
  width: "350px",
};
export const documentPlaceholder = function (html, data) {
  var string = html,
    key;
  for (key in data)
    string = string.replace(new RegExp("\\{" + key + "\\}", "gm"), data[key]);
  return string;
};

export const sampleEmailMessage = `<div style="padding: 20px; background-color: #fff;">
<h2 style="color: rgb(51, 51, 51);">Welcome to MyCompany!</h2><p style="color: rgb(0, 0, 0); font-size: medium; font-weight: 400;"><font color="#666666">Dear [Customer Name],</font></p><p style="color: rgb(0, 0, 0); font-size: medium; font-weight: 400;"><font color="#666666">Attached is the product brochure for our [Company Name]. It provides detailed information about our products, including specifications, pricing, and images.</font></p><p style="color: rgb(0, 0, 0); font-size: medium; font-weight: 400;"><font color="#666666">Please review the brochure at your convenience. If you have any questions or need further assistance, feel free to contact us.</font></p><p style="color: rgb(0, 0, 0); font-size: medium; font-weight: 400;"></p><p style="color: rgb(0, 0, 0); font-size: medium; font-weight: 400;"><a style="background-color: #493cd2; color: rgb(255, 255, 255); padding: 10px 20px; border-radius: 4px;">Website Link!</a></p>
</div>`;
